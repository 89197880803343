import "./src/styles/global.css";
import React from "react"
import { GdprContextProvider } from "./src/components/Gdpr/GdprContext";
import { CartContextProvider } from "./src/components/Cart/CartContext";
import { ProductWidgetContextProvider } from "./src/components/ProductWidget/ProductWidgetContext";
import { SearchContextProvider } from "./src/components/Search/SearchContext";

// const lunr = require('lunr')

// export function onClientEntry() {
//   fetch(`${__PATH_PREFIX__}/search_index.json`)
//     .then(function(response) {
//       return response.json()
//     })
//     .then(function(fullIndex) {
//       window.__LUNR__ = Object.keys(fullIndex).reduce(
//         (prev, key) => ({
//           ...prev,
//           [key]: {
//             index: lunr.Index.load(fullIndex[key].index),
//             store: fullIndex[key].store,
//           },
//         }),
//         {}
//       )
//     })
//     .catch(e => console.log('Failed fetch search index'))
// }

export const wrapRootElement = ({ element }) => (
    <CartContextProvider>
        <GdprContextProvider>
            <SearchContextProvider>
                <ProductWidgetContextProvider>
                    {element}
                </ProductWidgetContextProvider>
            </SearchContextProvider>
        </GdprContextProvider>
    </CartContextProvider>
)

export const onRouteUpdate = ({ location, prevLocation }) => {

    // extract campaigns
    // "?pk_campaign=Banane&pk_kwd=affe"
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const campaign = urlParams.get('pk_campaign')
    const kwd = urlParams.get('pk_kwd');

    if (campaign) {
        window.campaign = {
            campaign,
            kwd,
        }
    }
    
    if (document.getElementById("matomo")) {
        const _paq = window._paq || [];

        const url = location.pathname + location.search + location.hash
        const prevUrl =
          prevLocation &&
          prevLocation.pathname + prevLocation.search + prevLocation.hash
    
        // document.title workaround stolen from:
        // https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-analytics/src/gatsby-browser.js
        const sendPageView = () => {
          const { title } = document
    
          prevUrl && _paq.push(['setReferrerUrl', prevUrl])
          _paq.push(['setCustomUrl', url])
          _paq.push(['setDocumentTitle', title])
          _paq.push(['trackPageView']);
        }
    
        if ('requestAnimationFrame' in window) {
          requestAnimationFrame(() => {
            requestAnimationFrame(sendPageView)
          })
        } else {
          // simulate 2 rAF calls
          setTimeout(sendPageView, 32)
        }
    }
      
  
     
      return null;
  }