import React from 'react';

type ActionSearch= {
    type: "SEARCH";
    query: string;
};


type Action = ActionSearch;

export interface ISearchState {
    query: string,
    dispatch: (action: Action) => void,
}

let initialState: ISearchState = {
    query: "",
    dispatch: () => {},
  };

let SearchContext = React.createContext(initialState);


let reducer = (state: ISearchState , action: Action) => {

    let newState: ISearchState = {...{}, ...state};

    switch (action.type) {

        case "SEARCH":
            newState.query = action.query;
            break;
    }
    
    return newState;
};

const SearchContextProvider = (props: any) => {
    if (props.value) {
        initialState = props.value;
    }
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { ...state, dispatch };

      return (
      <SearchContext.Provider value={value}> {props.children} </SearchContext.Provider>
    );
  };
  
  let SearchContextConsumer = SearchContext.Consumer;
  
  export { SearchContext, SearchContextProvider, SearchContextConsumer };