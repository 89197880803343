import {camelCase} from 'lodash';

export function filterToLabel (filterKey: string): string {

    const translation: {[keys: string]: string} = {
        material: "Material",
        size: "Größe",
        gender: "Für",
        files: "Dateien",
        color: "Farbe",
        form: "Form",
        fuerProdukt: "Für",
    }

    if (!translation.hasOwnProperty(camelCase(filterKey))) {
        return filterKey
    }

    return translation[camelCase(filterKey)];

}

export function filterValuesToString (configuration: {[key: string]: any}): string {
    return Object.keys(configuration).map((key, index) => {
        let value = configuration[key];
        let label = filterToLabel(key) ? filterToLabel(key) + ": " : null;

        // // Remove unwanted keys from string list
        // if (["files"].indexOf(key) !== -1) {
        //     return null;
        // }

        if (key === "files") {
            const fileLength: number = Object.keys(configuration[key]).length;
            label = "";
            value =  fileLength + " Dateien"
        }

        if (key === "size") {
            value = value.length + " x " + value.width + " mm";
        }

        return label + value
    }).join(" | ");
}