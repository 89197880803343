import React from 'react';
import {cloneDeep, defaultsDeep} from 'lodash';

export type IProductWidgetStateFormValues = {[key: string]: {
    value: any;
    error: string;
    validationFunc: any;
}};

type WidgetState = "added" | "open";

type ActionSetInitial = {
    type: "SET_INITIAL";
    namespace: string;
    form: IProductWidgetStateFormValues;
}

type ActionSetValue = {
    type: "SET_VALUE";
    namespace: string;
    name: string;
    value: any;
}

type ActionClearValue = {
    type: "CLEAR_VALUE";
    namespace: string;
    name: string;
}

type ActionSetError = {
    type: "SET_ERROR";
    namespace: string;
    name: string;
    message: string;
}

type ActionClearError = {
    type: "CLEAR_ERROR";
    namespace: string;
    name: string;
}

type ActionSetWidgetState= {
    type: "SET_WIDGET_STATE";
    namespace: string;
    state: WidgetState;
}

type ActionClear = {
    namespace: string;
    type: "CLEAR";
}


type Action = ActionSetInitial | ActionSetValue | ActionClearValue  | ActionClear | ActionSetError | ActionClearError | ActionSetWidgetState;

export interface IProductWidgetState {
    forms: {[key: string]: {
        values: IProductWidgetStateFormValues;
        state: WidgetState;
    }};
    dispatch: (action: Action) => void,
}

let initialState: IProductWidgetState = {
    forms: {},
    dispatch: () => {},
};


// Check if all

let ProductWidgetContext = React.createContext(initialState);

let reducer = (state: IProductWidgetState , action: Action) => {
    let newState: IProductWidgetState = cloneDeep(state);

    switch (action.type) {

        case "SET_INITIAL":
            const newNamespaceConfiguration: {
                values: IProductWidgetStateFormValues;
                state: WidgetState;
            } = {
                state: "open",
                values: action.form,
            };
            if (!newState.forms[action.namespace]) {
                newState.forms[action.namespace] = newNamespaceConfiguration
            } else {
                newState.forms[action.namespace] = defaultsDeep(newState.forms[action.namespace], newNamespaceConfiguration);
            }
            break;

        case "SET_VALUE":
            newState.forms[action.namespace].values[action.name].value = action.value;
            break;

        case "CLEAR_VALUE":
             if (newState.forms[action.namespace].values[action.name]) {
                 newState.forms[action.namespace].values[action.name].value = null;
             }
            break;

        case "SET_ERROR":
            newState.forms[action.namespace].values[action.name].error = action.message;
            break;

        case "CLEAR_ERROR":
                newState.forms[action.namespace].values[action.name].error = null;
            break;

        case "SET_WIDGET_STATE":
            newState.forms[action.namespace].state = action.state;
            break;

        case "CLEAR":
                delete newState.forms[action.namespace];
                break;
    }
    return newState;
};

const ProductWidgetContextProvider = (props: any) => {
    if (props.value) {
        initialState = props.value;
    }
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { ...state, dispatch };
  
      return (
      <ProductWidgetContext.Provider value={value}> {props.children} </ProductWidgetContext.Provider>
    );
  };
  
  let ProductWidgetContextConsumer = ProductWidgetContext.Consumer;
  
  export { ProductWidgetContext, ProductWidgetContextProvider, ProductWidgetContextConsumer };