import React from 'react';
import IGdprData from '../../interfaces/IGdpr';
import { loadJs } from './GdprJsLoader';


type ActionSaveSettings= {
    type: "SAVE_SETTINGS";
    settings: {[index: string]: boolean};
};

type ActionDefaultSettings= {
    type: "DEFAULT";
};

type ActionAcceptAllSettings= {
    type: "ACCEPT_ALL";
};

type ActionDialog= {
    type: "HIDE_DIALOG" | "SHOW_DIALOG";
};

type ActionSettings= {
    type: "HIDE_SETTINGS" | "SHOW_SETTINGS";
};


type Action = ActionSaveSettings | ActionDefaultSettings | ActionDialog | ActionSettings | ActionAcceptAllSettings;

export interface IGdprState {
    settings: IGdprData;
    hideDialog: boolean;
    hideSettings: boolean;
    changed: boolean;
    dispatch: (action: Action) => void,
}

let initialState: IGdprState = {
    settings: {
        categories: ["Funktion", "Multimedia", "Sonstiges"],
        services: [
            {
                name: "Datenschutz Einstellungen", // unique name of the service
                // Describes the function and the need of use of the service.
                description: "Die hier getätigten Einstellungen über deine Privatsphäre speichern wir lokal auf Ihrem Computer ab, damit Sie beim nächsen Besuch wieder berücksichtigt werden. Die Daten sind nur lokal gesichert und werden an keine anderen Server übermittelt.",
                allowed: false, // should be always false and will be changed via user interaction
                default: true, // the default value on the users page. If true the checkbox will be selected
                category: "Funktion", // Name of a category. All services with the same string will be put together on the settings page.
                cookies: [{type: "LocalStorage", name: "gdpr",},], // A list of cookies or local storage properties used by the service
                domain: "his-print-service.de" // the domain communicating with to provide information to the user
            },
            {
                name: "Anfrage Funktion",
                description: "Wenn Sie Produkte in unser Anfrageformlar laden dann sind diese Informationen bei einem Neu-Laden der Website gelöscht. Um dies zu verhindern setzen wir auf Ihrem Computer die Inhalte Ihres Anfrageformulars. Die Daten sind nur lokal gesichert und werden an keine anderen Server übermittelt.",
                allowed: false,
                default: true,
                category: "Funktion",
                cookies: [{type: "LocalStorage", name: "cart",},],
                domain: "his-print-service.de"
            },
            {
                name: "Chat",
                description: "Über den Chat können Sie direkt mit uns in Kontakt treten! Damit dies funktioniert benötigen wir einige Cookies damit andere nicht Ihre Nachrichten lesen können.",
                allowed: false,
                default: true,
                category: "Funktion",
                cookies: [
                    {type: "Cookie", name: "connect.sid", },
                    {type: "Cookie", name: "rc_rid",  },
                    {type: "Cookie", name: "rc_room_type",  },
                    {type: "Cookie", name: "rc_token",  },
                    {type: "Cookie", name: "rc_uid",  },
                    {type: "Cookie", name: "session_api_session", },
                ],
                domain: "chat.his-print-service.de"
            },
            {
                name: "Youtube",
                description: "Zum Abspielen unserer Video-Inhalte werden Videos von Youtube auf unsere Seite integriert. Durch den Abruf dieser Videos setzt Youtube Cookies und kann die Verbindung über unsere Seite auswerten. Alle nötigen Informationen finden Sie auf der <a href=\"https://policies.google.com/privacy?hl=de&gl=de\" target=\"_blank\"> Datenschutzseite</a> von Google",
                allowed: false,
                default: false,
                category: "Multimedia",
                cookies: [
                    {type: "Cookie", name: "__Secure-3PAPISID"},
                    {type: "Cookie", name: "__Secure-3PSID"},
                    {type: "Cookie", name: "__Secure-APISID"},
                    {type: "Cookie", name: "__Secure-HSID"},
                    {type: "Cookie", name: "__Secure-SSID"},
                    {type: "Cookie", name: "APISID"},
                    {type: "Cookie", name: "CONSENT"},
                    {type: "Cookie", name: "HSID"},
                    {type: "Cookie", name: "LOGIN_INFO"},
                    {type: "Cookie", name: "PREF"},
                    {type: "Cookie", name: "s_gl"},
                    {type: "Cookie", name: "SAPISID"},
                    {type: "Cookie", name: "SIDCC"},
                    {type: "Cookie", name: "SID"},
                    {type: "Cookie", name: "SSID"},
                    {type: "Cookie", name: "VISITOR_INFO1_LIVE"},
                    {type: "Cookie", name: "YSC"},
                ],
                domain: "youtube.com"
            },
            {
                name: "Analyse",
                description: "Hierbei handelt es sich um ein Analysesystem, dass uns hilft auszuwerten welche Teile unserer Website von unseren Gästen häufiger besucht werden. Die Daten sind dabei anonymisiert. Helfen Sie mit, unseren Service weiter zu verbessern.",
                allowed: false,
                default: false,
                category: "Sonstiges",
                cookies: [
                    {type: "Cookie", name: "_pk_id.1.1fff"},
                    {type: "Cookie", name: "_pk_ses.1.1fff"},
                    {type: "Cookie", name: "_pk_ref.1.1fff"},
                    {type: "Cookie", name: "mtm_consent_removed"},
                    {type: "Cookie", name: "io"}
                ],
                domain: "analytics.his-print-service.de"
            },
        ]
    },
    hideDialog: false,
    hideSettings: true,
    changed: false,
    dispatch: () => {},
  };

let GdprContext = React.createContext(initialState);


let reducer = (state: IGdprState , action: Action) => {

    let newState: IGdprState = {...state};

    switch (action.type) {

        case "SAVE_SETTINGS":
            for (const key in action.settings) {
                for (const serviceKey in newState.settings.services) {
                    const service = newState.settings.services[serviceKey];
                    if (service.name === key) {
                        newState.settings.services[serviceKey].allowed = action.settings[key];
                    }
                }
            }
            newState.changed = true;

            loadJs(newState.settings.services);
            break;

        case "DEFAULT":
                for (const key in newState.settings.services) {
                    newState.settings.services[key].allowed = newState.settings.services[key].default;
                }
                newState.changed = true;
                loadJs(newState.settings.services);
                break;

        case "ACCEPT_ALL":
            for (const key in newState.settings.services) {
                newState.settings.services[key].allowed = true;
            }
            newState.changed = true;
            loadJs(newState.settings.services);
            break;

        case "HIDE_DIALOG":
            newState.hideDialog = true;
            break;
        case "SHOW_DIALOG":
            newState.hideDialog = false;
            break;

        case "HIDE_SETTINGS":
                newState.hideSettings = true;
                break;
        case "SHOW_SETTINGS":
            newState.hideSettings = false;
            break;
    }

    // Save to LocalStorage
    let allowToLocalStorage: boolean = false;
    let lsSettings: {[key: string]: boolean} = {};
    for (const service of newState.settings.services) {
        lsSettings[service.name] = service.allowed;
        if (service.name === "Datenschutz Einstellungen" && service.allowed) {
            allowToLocalStorage = true;
        }
    }
    if (allowToLocalStorage) {
        localStorage.setItem("gdpr", JSON.stringify(lsSettings));
    }
    
   

    return newState;
};

const GdprContextProvider = (props: any) => {
    if (props.value) {
        initialState = props.value;
    }
    // Read from LocalStorage
    if (localStorage.getItem("gdpr")) {
        const lsSettings = JSON.parse(localStorage.getItem("gdpr"));
        for (const key in lsSettings) {
            for (const serviceKey in initialState.settings.services) {
                if (initialState.settings.services[serviceKey].name === key) {
                    initialState.settings.services[serviceKey].allowed = lsSettings[key];
                }
            }
        }
        initialState.changed = true;
        initialState.hideDialog = true;
    }

    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { ...state, dispatch };

    loadJs(value.settings.services, true);
    
      return (
      <GdprContext.Provider value={value}> {props.children} </GdprContext.Provider>
    );
  };
  
  let GdprContextConsumer = GdprContext.Consumer;
  
  export { GdprContext, GdprContextProvider, GdprContextConsumer };