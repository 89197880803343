import React from 'react';
import {merge} from 'lodash';
import { IProduktMinimal } from '../../interfaces/IProdukt';
import useGdprIsAllowed from '../Gdpr/useGdprIsAllowed';
import { filterValuesToString } from '../../utils/FilterHelper';

export type CartPosition = {
    product: IProduktMinimal;
    configuration: {[key: string]: any};
    qty: number;
    message?: string,
}

export type CartContact = {
    name: string;
    phone: string;
    email: string;
    message: string;
    dataprivacy: boolean;
};

type ActionAddProduct= {
    type: "ADD_PRODUCT";
} & CartPosition;

type ActionRemoveProduct= {
    type: "REMOVE_PRODUCT";
    position: CartPosition;
};

type ActionUpdateProduct = {
    type: "UPDATE_PRODUCT";
    index: number;
    data: Partial<CartPosition>;
}

type ActionUpdateContact = {
    type: "UPDATE_CONTACT";
    data: Partial<CartContact>;
}

type ActionOpenMiniCart = {
    type: "OPEN_MINI_CART";
}

type ActionCloseMiniCart = {
    type: "CLOSE_MINI_CART";
}

type ActionClear = {
    type: "CLEAR";
}

type Action = ActionAddProduct | ActionRemoveProduct | ActionOpenMiniCart | ActionCloseMiniCart | ActionUpdateProduct | ActionUpdateContact | ActionClear;

export interface ICartState {
    products: Array<CartPosition>;
    notes?: string;
    contact: CartContact;
    miniCartOpen: boolean;
    version: number;
    dispatch: (action: Action) => void,
}

let initialState: ICartState = {
    products: [],
    contact: { name: "", email: "", phone: "", message: "", dataprivacy: false},
    miniCartOpen: false,
    version: 3,
    dispatch: () => {},
};

let CartContext = React.createContext(initialState);


let reducer = (state: ICartState , action: Action) => {

    let newState: ICartState = {...{}, ...state};

    switch (action.type) {

        case "ADD_PRODUCT":
            // Check for same configuration
            const configuration = JSON.stringify(action.configuration);
            let found = false;
            newState.products.forEach((product, index) => {
                if (action.product.name === product.product.name && configuration === JSON.stringify(product.configuration)) {
                    newState.products[index].qty = Number(product.qty) + Number(action.qty);
                    found = true;
                }
            });

            if (!found) {
                // extract message from filters if available
                let message = action.message ? action.message : action.configuration.message ? action.configuration.message : "";

                newState.products.push({product: action.product, configuration: action.configuration, qty: action.qty, message});
            }
            break;

        case "REMOVE_PRODUCT":
                newState.products.forEach((position, index) => {
                    if (position.product === action.position.product && JSON.stringify(position.configuration) === JSON.stringify(action.position.configuration)) {
                        newState.products.splice(index, 1);
                    }
                });
                break;
        
        case "UPDATE_PRODUCT":
            newState.products[action.index] = {...{}, ...newState.products[action.index], ... action.data};
            break;
        
        case "UPDATE_CONTACT":
                newState.contact = merge( newState.contact, action.data);
                break;

        case "OPEN_MINI_CART":
            newState.miniCartOpen = true;
            break;

        case "CLOSE_MINI_CART":
            newState.miniCartOpen = false;
            break;

        case "CLEAR":
            newState.products = [];
            newState.contact =  { name: "", email: "", phone: "", message: "", dataprivacy: false};
            break;
    }

    // track new products
    if (document.getElementById("matomo") && _paq && ["UPDATE_PRODUCT", "REMOVE_PRODUCT", "ADD_PRODUCT"].indexOf(action.type) !== -1) {
        for (const cartItem of newState.products) {
            _paq.push(['addEcommerceItem',
                cartItem.product.url, // (required) SKU: Product unique identifier
                cartItem.product.name + " | " + filterValuesToString(cartItem.configuration), // (optional) Product name
                [cartItem.product.produktTyp], // (optional) Product category, string or array of up to 5 categories
                cartItem.qty // (optional, default to 1) Product quantity

            ]);
        }
       
        // Records the cart for this visit
        _paq.push(['trackEcommerceCartUpdate',
        30 * newState.products.length]); // (required) Cart amount - 30 is a average price because our system dont use prices.
    } 
    
    return newState;
};

const updateCartState = (oldState: ICartState): ICartState => {
    // update to 1.2.1 at 2021-07-09 -> remove after 6 month
    if (!oldState.version) {
        oldState.products = []; // remove products because image object changed
        oldState.version = 3;
    }

    return oldState;
}

const CartContextProvider = (props: any) => {
    if (props.value) {
        initialState = props.value;
    }

     // Read from LocalStorage
     if (localStorage.getItem("cart")) {
        const oldCart = updateCartState(JSON.parse(localStorage.getItem("cart")));
        initialState.contact = oldCart.contact;
        initialState.products = oldCart.products;
    }

    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { ...state, dispatch };

    // Save to LocalStorage
    const isAllowed = useGdprIsAllowed("Anfrage Funktion");
    if (isAllowed) {
        localStorage.setItem("cart", JSON.stringify(value));
    }

      return (
      <CartContext.Provider value={value}> {props.children} </CartContext.Provider>
    );
  };
  
  let CartContextConsumer = CartContext.Consumer;
  
  export { CartContext, CartContextProvider, CartContextConsumer };