import { IGdprService } from "../../interfaces/IGdpr";

const purgeData = (data: Array<{name: string, type: "LocalStorage" | "Cookie"}>) => {

    for (const item of data) {
        if (item.type === "Cookie") {
            document.cookie = item.name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }

        if (item.type === "LocalStorage") {
            localStorage.removeItem(item.name);
        }
    }
}

export function loadJs (services: Array<IGdprService>, fromMemory?: boolean) {

    for (const service of services) {
        // Add a case for each service handling javscript code to add or remove the js files.
        switch (service.name) {
             // Matomo
            case "Analyse":

            if (service.allowed) {
                if (!document.getElementById("matomo")) {
                    var u="//analytics.his-print-service.de/";
                    _paq.push(['setConsentGiven']);
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', '1']);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.type='text/javascript'; g.async=true; g.defer=true; g.id='matomo'; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

                    if (!fromMemory) {
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        const campaign = urlParams.get('pk_campaign')
                        const kwd = urlParams.get('pk_kwd');
                        let url: string = window.location.toString();
                        if (window.hasOwnProperty("campaign") && !campaign && !kwd) { 
                            // ?pk_campaign=Hafer&pk_kwd=prerd
                            url += "?pk_campaign=" + window.campaign.campaign;
                            if (window.campaign.kwd) {
                                url += "&pk_kwd=" + window.campaign.kwd;
                            }
                        }
    
                        _paq.push(['setCustomUrl', url])
                        _paq.push(['trackPageView']);
                    }
                }
            } else {
                if (document.getElementById("matomo")) {
                    _paq.push(['forgetConsentGiven']);
                    _paq.push([function() { this.deleteCookies(); }])
                    document.getElementById("matomo").remove();
                    purgeData(service.cookies);
                }
            }
            break;

            // RocketChat 
            case "Chat":
                if (service.allowed) {
                    if (document.getElementsByClassName('rocketchat-widget').length <= 0) {
                        (function(w, d, s, u) {
                            w.RocketChat = function(c) { w.RocketChat._.push(c) }; w.RocketChat._ = []; w.RocketChat.url = u;
                            var h = d.getElementsByTagName(s)[0], j = d.createElement(s);
                            j.async = true; j.src = 'https://chat.his-print-service.de/livechat/rocketchat-livechat.min.js?_=201903270000';
                            h.parentNode.insertBefore(j, h);
                        })(window, document, 'script', 'https://chat.his-print-service.de/livechat');
                    }
                } else {
                    if (document.getElementsByClassName('rocketchat-widget').length >= 1) {

                        if (typeof window !== `undefined`) {
                            window.RocketChat(function() {
                                this.hideWidget();
                            });
                        }

                        const container = document.getElementsByClassName("rocketchat-widget");
                        for (const cont of container) {
                            cont.remove();
                        }
                        purgeData(service.cookies);
                    }
                }

                break;

            default:
                if (!service.allowed) {
                        purgeData(service.cookies);
                }
                break;

        }
    }

   


}