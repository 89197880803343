import React from 'react';
import { GdprContext } from './GdprContext';

export default function useGdprIsAllowed(key: string) {
    const { settings } = React.useContext(GdprContext);

    for (const service of settings.services) {
        if (service.name === key) {
            return service.allowed;
        }
    }

}